"use client"

import clsx from "clsx"

import type { Nullish, RemoveRecordFromUnion } from "~/@types/generics"
import type { TSbAsset } from "~/components/ui/Asset/_data/serializer"
import { Image, type ImageProps } from "~/components/ui/Image"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import ShopTheLookPanelTrigger from "~/components/ui/ShopTheLookPanel/components/Trigger"
import type { TVideo } from "~/components/ui/Video"
import { VideoWithRatio } from "~/components/ui/VideoMobileDesktop"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { hideFrom, showFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

type AssetProps = TSbAsset & {
  imageProps?: Omit<ImageProps, keyof TImage>
  imageMobileProps?: Omit<ImageProps, keyof TImage>
  display?: RemoveRecordFromUnion<Sprinkles["display"]>
  videos?: {
    desktop: Nullish<TVideo>
    mobile: Nullish<TVideo>
    videoClassname?: string
    videoRatio: {
      mobile: Sprinkles["ratio"]
      tablet: Sprinkles["ratio"]
    }
  }
} & Omit<ImageProps, keyof TImage>

export function Asset({
  className,
  imageProps = {},
  image,
  imageMobileProps = {},
  imageMobile,
  videos,
  display = "block",
  shopTheLook,
  ...props
}: AssetProps) {
  return (
    <div className={clsx(css.Asset, className)}>
      {videos?.desktop && videos.mobile ? (
        <>
          <VideoWithRatio
            className={clsx(videos.videoClassname, hideFrom("mobile", "block"), showFrom("tablet", "block"))}
            ratio={videos.videoRatio.tablet}
            video={videos.desktop}
          />
          <VideoWithRatio
            className={clsx(videos.videoClassname, hideFrom("tablet", "block"), showFrom("mobile", "block"))}
            ratio={videos.videoRatio.mobile}
            video={videos.mobile}
          />
        </>
      ) : (
        <>
          {image && (
            <Image
              {...props}
              {...imageProps}
              {...image}
              className={clsx(
                imageProps.className,
                sprinkles({ display: { mobile: imageMobile ? "none" : display, tablet: display } })
              )}
            />
          )}
          {imageMobile && (
            <Image
              {...props}
              {...imageMobileProps}
              {...imageMobile}
              className={clsx(imageMobileProps.className, sprinkles({ display: { tablet: "none" } }))}
            />
          )}
        </>
      )}
      {shopTheLook && <ShopTheLookPanelTrigger className={clsx(css.shopTheLook)} products={shopTheLook} />}
    </div>
  )
}
