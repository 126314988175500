import clsx from "clsx"

import RoundedCta, { type RoundedCtaProps } from "~/components/ui/RoundedCta"
import Icon from "~/components/abstracts/Icon"
import { Stack, type StackProps } from "~/components/abstracts/Stack"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

export type SliderArrowNavigationProps = {
  className?: string
  leftArrowClassName?: string
  rightArrowClassName?: string
  theme?: RoundedCtaProps["theme"]
  prevDisabled?: boolean
  nextDisabled?: boolean
  onPrevClick(): void
  onNextClick(): void
} & StackProps

function SliderArrowNavigation({
  leftArrowClassName,
  rightArrowClassName,
  prevDisabled,
  nextDisabled,
  theme = "white",
  onPrevClick,
  onNextClick,
  ...props
}: SliderArrowNavigationProps) {
  const t = useTranslate()

  return (
    <Stack gap={4} {...props}>
      <RoundedCta
        className={clsx(leftArrowClassName)}
        theme={theme}
        onClick={onPrevClick}
        notAllowed={prevDisabled}
        aria-label={t("aria_arrow_prev")}
      >
        <Icon name="ArrowLeft" width={18} />
      </RoundedCta>
      <RoundedCta
        className={clsx(rightArrowClassName)}
        theme={theme}
        onClick={onNextClick}
        notAllowed={nextDisabled}
        aria-label={t("aria_arrow_next")}
      >
        <Icon name="ArrowRight" width={18} />
      </RoundedCta>
    </Stack>
  )
}

export { SliderArrowNavigation }
