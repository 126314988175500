"use client"

import dynamic from "next/dynamic"

import type { PropsWithClassName } from "~/@types/generics"
import RoundedCta from "~/components/ui/RoundedCta"
import type { ShopTheLookPanelProps } from "~/components/ui/ShopTheLookPanel/components/"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

const Panel = dynamic(() => import("~/components/ui/ShopTheLookPanel/components/"), { ssr: false })

export type ShopTheLookPanelTriggerProps = {
  products: ShopTheLookPanelProps["products"]
}

function ShopTheLookPanelTrigger({ className, products }: PropsWithClassName<ShopTheLookPanelTriggerProps>) {
  const t = useTranslate()
  const { add } = usePanel()
  const handleOnClickAsset = () => {
    add(<Panel products={products} />)
  }

  if (!products?.length) return null

  return (
    <RoundedCta className={className} onClick={handleOnClickAsset}>
      {t("shop_the_look_cta")}
    </RoundedCta>
  )
}

export default ShopTheLookPanelTrigger
